import React from "react";
var createReactClass = require("create-react-class");
import { Provider } from "react-redux";

import FlashMessage from "./flash_message.js";
import ReportCanvasWithProvider from "./reportCanvas.js.jsx";
import ReportLanguage from "./report_language.js";
import ReportSchedule from "./../components/reports/schedule";
import ReportEmails from "./../components/reports/emails";
import ReportCompareYearToYear from "./report_compare_year_to_year.js";
import CompareDailyOptions from "../components/reports/report_settings/compare_daily_options.jsx";
import DailyOptionsMonthToDate from "../components/reports/report_settings/daily_options_month_to_date.jsx";
import ReportDayOfWeek from "./report_day_of_week.js";
import ReportSiteName from "./report_site_name.js";
import ReportCustomVariables from "./report_custom_variables.js";
import ReportAlwaysSendPreviewFirst from "./report_always_send_preview_first.js";
import ReportPreviewRecipient from "./report_preview_recipient.js";
import ReportEmailSubject from "./report_email_subject.js";
import ReportMessage from "./report_message.js";
import ReportActionButtons from "./report_action_buttons.js";
import ReportSetting from "../components/reports/report_settings/setting.jsx";

import ReportFormLoader from "../helpers/report_form_loader";
import LoadSpinner from "../components/utility/load_spinner.jsx";
import { reportFormValidation } from "../helpers/report_form_validation";
import PPCMarkup from "../components/reports/ppc_markup";
import PubliclySharedReport from "../components/reports/report_settings/publicly_shared_report.jsx";
import SingleMetricComparisonDisabledSetting from "../components/reports/report_settings/single_metric_comparison.jsx";
import ReportPreviewFetcher from "../helpers/widget_preview/report_preview_fetcher.js";

import { ChartPieIcon, BoltIcon, CalendarDaysIcon, ShareIcon, Cog6ToothIcon } from "@heroicons/react/20/solid";
import FlashMessageWarnings from "./flash_message_warning.jsx";
import AccountSelectorsList from "./accountSelectorsList.jsx";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ReportForm = createReactClass({
  getInitialState: function () {
    return {
      name: "",
      loading: true,
      activeTab: "widgets",
      openAdhocModal: false,
    };
  },

  setActiveTab: function (tabName) {
    tabName = tabName.toLowerCase();
    if (["widgets", "integrations", "schedule", "share", "settings"].includes(tabName)) {
      this.setState({ activeTab: tabName });
    }
    if (tabName === "widgets") {
      new ReportPreviewFetcher().fetch();
    }
  },

  isActiveTab: function (tabName) {
    return this.state.activeTab === tabName.toLowerCase();
  },

  componentDidMount: function () {
    ReportFormLoader.init(this.props.match.params.id, () => {
      // Step 1: Parse the URL to get query parameters
      const queryParams = new URLSearchParams(window.location.search);

      // Step 2: Get the `openTab` query parameter value, if not present default to "Widgets"
      // Convert the tab name to lowercase to handle case sensitivity
      const tabName = queryParams.get('openTab') ? queryParams.get('openTab').toLowerCase() : 'widgets';

      // Step 3: Set the active tab based on the `tabName`
      // Convert the first character to uppercase and the rest to lowercase to match the expected format
      const formattedTabName = tabName.charAt(0).toUpperCase() + tabName.slice(1).toLowerCase();
      this.setActiveTab(formattedTabName);

      this.setState({ loading: false });

      if (queryParams.get('openAdhocModal') === 'true') {
        // console.log("Setting adhoc modal state to true");
        this.setState({ openAdhocModal: true });
      }
    });

    store.subscribe(this.updateName);
  },

  updateName: function () {
    this.setState({ name: store.getState().reportFormReducer.name });
  },


  render: function () {
    let { isActiveTab, setActiveTab } = this;
    const tabs = [
      { name: "Widgets", contentId: "widget-tab", icon: ChartPieIcon, current: true },
      { name: "Integrations", contentId: "integrations-tab", icon: BoltIcon, current: false },
      { name: "Schedule", contentId: "schedule-tab", icon: CalendarDaysIcon, current: false },
      { name: "Share", contentId: "share-tab", icon: ShareIcon, current: false },
      { name: "Settings", contentId: "settings-tab", icon: Cog6ToothIcon, current: false },
    ];

    return (
      <Provider store={store}>
        <div>
          {this.state.loading && <LoadSpinner />}

          <h2 className="tw-page-title mb-4 mt-2">{this.state.name || "Loading..."}</h2>
          <FlashMessage />
          <FlashMessageWarnings setActiveTab={this.setActiveTab} reportId={this.props.match.params.id} />

          <div id="report-tabs-container" className="mb-4 bg-white">
            <div className="sm:hidden">
              <select
                aria-label="Selected tab"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                name="tabs"
                id="tabs"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      href="#"
                      data-tab-content-id={tab.contentId}
                      onClick={() => setActiveTab(tab.name)}
                      className={classNames(
                        isActiveTab(tab.name)
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <tab.icon
                        className={classNames(
                          isActiveTab(tab.name) ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
                          "-ml-0.5 mr-2 h-5 w-5"
                        )}
                        aria-hidden="true"
                      />
                      <span>{tab.name}</span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
          </div>

          <div id="report-tabs-content-container">
            <div id="widget-tab" className="tab-content" style={{ display: isActiveTab("widgets") ? "block" : "none" }}>
              <ReportCanvasWithProvider />
            </div>

            <div
              id="integrations-tab"
              className="tab-content"
              style={{ display: isActiveTab("integrations") ? "block" : "none" }}
            >
              <h3 className="tw-medium-header text-center mt-8 mb-4">Integrations</h3>
              <p className="text-sm italic mb-2">
                The integration account you select here will apply to all widgets of this report, except the widgets
                where you select a widget-specific account. This makes it easier if you want to change the account for
                the whole report, or when you duplicate a report for another client or division.
              </p>
              {reportFormValidation.requiredIntegrations().length === 0 && (
                <p className="has-text-centered m-t-10 is-italic text-center my-32">
                  No integration to configure yet. You need to add widgets first, and then you will see the related
                  account selectors here.
                </p>
              )}

              <AccountSelectorsList />
            </div>

            <div
              id="schedule-tab"
              className="tab-content"
              style={{ display: isActiveTab("schedule") ? "block" : "none" }}
            >
              <h3 className="tw-medium-header text-center mt-8 mb-4">Schedules</h3>

              <article className="mb-4 p-4 bg-white rounded-md shadow-md overflow-hidden">
                <ReportSchedule interval="monthly" />
                <div className="flex flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-1/2 mb-4 md:pr-4">
                    <ReportEmails interval="monthly" />
                    <ReportCompareYearToYear interval="monthly" tabIndex="6" />
                  </div>
                  <div className="w-full md:w-1/2 mb-4 md:pl-4">
                    <ReportEmailSubject interval="monthly" tabIndex="11" />
                    <ReportMessage interval="monthly" tabIndex="12" />
                  </div>
                </div>
              </article>

              <article className="mb-4 p-4 bg-white rounded-md shadow-md">
                <ReportSchedule interval="weekly" />
                <div className="flex flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-1/2 mb-4 md:pr-4">
                    <ReportEmails interval="weekly" />
                    <ReportDayOfWeek interval="weekly" tabIndex="6" />
                    <ReportCompareYearToYear interval="weekly" />
                  </div>
                  <div className="w-full md:w-1/2 mb-4 md:pl-4">
                    <ReportEmailSubject interval="weekly" tabIndex="11" />
                    <ReportMessage interval="weekly" tabIndex="12" />
                  </div>
                </div>
              </article>

              <article className="mb-4 p-4 bg-white rounded-md shadow-md overflow-hidden">
                <ReportSchedule interval="daily" />
                <div className="flex flex-wrap md:flex-nowrap">
                  <div className="w-full md:w-1/2 mb-4 md:pr-4">
                    <ReportEmails interval="daily" />
                    <CompareDailyOptions interval="daily" tabIndex="6" />
                    <DailyOptionsMonthToDate tabIndex="6" />
                  </div>
                  <div className="w-full md:w-1/2 mb-4 md:pl-4">
                    <ReportEmailSubject interval="daily" tabIndex="11" />
                    <ReportMessage interval="daily" tabIndex="12" />
                  </div>
                </div>
              </article>
            </div>

            <div
              id="share-tab"
              className="tab-content flex flex-wrap md:flex-nowrap"
              style={{ display: isActiveTab("share") ? "flex" : "none" }}
            >
              <div className="w-full">
                <h4 className="tw-medium-header text-center mt-8 mb-4" id="">
                  Publicly shareable URL &amp; PDF
                </h4>
                <PubliclySharedReport tabIndex="13" />
              </div>
            </div>
            <div
              id="settings-tab"
              className="tab-content flex flex-wrap md:flex-nowrap"
              style={{ display: isActiveTab("settings") ? "flex" : "none" }}
            >
              <div className="w-full md:w-1/2 md:pr-4">
                <h3 className="tw-medium-header text-center my-4">Base settings</h3>
                <ReportSiteName tabIndex="7" />
                <ReportLanguage tabIndex="4" />

                <h4 className="tw-medium-header text-center mt-8 mb-4" id="">
                  White-label options
                </h4>
                <p className="text-sm italic">
                  Those settings will overwrite the equivalent settings from "Business & brand", but only for the
                  current report.
                </p>

                <ReportSetting settingName="business_name" label="Business Name" type="text" />
                <ReportSetting settingName="reply_address" label="Email reply address" type="text" />
                <ReportSetting settingName="logo_url" label="Report Logo URL" type="text" />
                <ReportSetting settingName="physical_address" label="Physical address" type="textarea" />
              </div>

              <div className="w-full md:w-1/2 md:pl-4">
                <h3 className="tw-medium-header text-center my-4">Report preview settings</h3>
                <ReportAlwaysSendPreviewFirst tabIndex="9" />
                <ReportPreviewRecipient tabIndex="10" />

                <h3 className="tw-medium-header text-center my-4">Widgets settings</h3>
                <SingleMetricComparisonDisabledSetting />

                <h3 className="tw-medium-header text-center my-4">PPC Markup</h3>
                <p className="text-sm italic">
                  By default, the global PPC markup from the "Business &amp; Brand" settings will apply to all reports.
                  But you can set report-level ones here.
                </p>
                <PPCMarkup showEnableToggle={true} />

                <ReportCustomVariables />
              </div>
            </div>
          </div>
          <div className="mt-2 -mr-2 p-2 border-t border-gray-300 bg-white sticky bottom-0">
            <ReportActionButtons tabIndex="14" showAdHocModal={this.state.openAdhocModal} />
          </div>
        </div>
      </Provider>
    );
  },
});

export default ReportForm;
