import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FilterValidation from "../../../helpers/filter_validation";
import GenericListEditor from "../../../widgets/data/list_editor";
import AccountSelector from "../../../integrations/account_selector";
import MetricFilterer from "../../../helpers/metric_filterer";
import { DimensionFilterer } from "../../../helpers/metric_filterer";

import WidgetPreviewFetcher from "../../../helpers/widget_preview/widget_preview_fetcher";
class ListEditor extends React.Component {
  constructor(props) {
    super(props);

    const integration = integrationStore.findIntegrationByOAuthProviderName(this.props.oAuthProviderName);
    const widgetConfig = integration.findIntegrationWidgetConfigByType("list");
    let metrics = MetricFilterer.byValidDimensions(props.metrics, widgetConfig.valid_dimensions);
    let dimensions = DimensionFilterer.byValidDimensions(props.dimensions, widgetConfig.valid_dimensions);

    this.state = {
      integration: integration,
      metrics: metrics,
      dimensions: dimensions,
    };
  }

  saveAndClose() {
    let errors = [];
    const filterParamNames = {
      dimension: "dimension",
      operator: "operator",
      value: "value",
    };

    if (!this.props.dimension) {
      errors.push("Select what you want to list");
    }

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (
      (this.props.sort_by == "metric[1]" && !this.props.secondary_metric_name) ||
      (this.props.sort_by == "metric[2]" && !this.props.third_metric_name)
    ) {
      errors.push(`Invalid "Sort By". You can't sort by a metric that is not set.`);
    }

    if (!FilterValidation.validate(this.props.filters, filterParamNames)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, []));

    this.props.save();

    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    this.props.closeWidgetEditModal();
  }

  render() {
    // Config objects
    const integrationConfig = this.state.integration;
    const widgetConfig = this.state.integration.findIntegrationWidgetConfigByType("list");

    // Metrics (filtered on dimension if need be)
    let metricsList = this.state.metrics.filter(
      (metric) => metric.valid_dimensions == undefined || metric.valid_dimensions.includes(this.props.dimension)
    );

    // Keep only the dimensions we can actually filter on
    let availableFilterDimension = this.props.filterDimensionList;
    if (widgetConfig.filterByCurrentDimensionOnly === true) {
      availableFilterDimension = this.props.filterDimensionList.filter(
        (dimension) => dimension.value === this.props.dimension
      );
    }

    // Show the sort options if enabled
    let sortByOptions = [];
    if (widgetConfig.sortEnabled === true) {
      if (widgetConfig.sortByOptions) {
        sortByOptions = widgetConfig.sortByOptions;
      } else {
        sortByOptions = integrationConfig.sortByOptions;
      }
    }

    return (
      <GenericListEditor
        {...this.props}
        metricsList={metricsList}
        dimensions={this.state.dimensions}
        filterDimensionList={availableFilterDimension}
        sortByOptions={sortByOptions}
        name={this.props.providerName + " List"}
        saveAndClose={this.saveAndClose.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        shortFilterVariable={true}
        offerPreviousPeriodComparison
        filtersEnabled={widgetConfig.filtersEnabled !== false}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
            integrationProviderName={this.props.oAuthProviderName}
            accountSelectorLabel="Account (optional)"
            accountListFunction={this.state.integration.accountList}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetricLabel: (event) => {
      dispatch(updateEditorWidgetConfig("metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = String(selected.label.split(" --")[0]).trim();
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_label", label, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      let previousDimension = store.getState().widgetReducer.editorConfig.dimension;

      if (previousDimension != value) {
        dispatch(updateEditorWidgetConfig("metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("metric_label", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("secondary_metric_label", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("third_metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("third_metric_label", "", ownProps.widgetIndex));

        // We need to reset the filter dimensions if dimension change AND that we can only filter on current dimension
        const integration = integrationStore.findIntegrationByOAuthProviderName(ownProps.oAuthProviderName);
        const widgetConfig = integration.findIntegrationWidgetConfigByType("List");
        if (widgetConfig.filterByCurrentDimensionOnly) {
          let state = store.getState().widgetReducer.editorConfig;
          let newFilters = state.filters.map((filter) => {
            if (filter.dimension) {
              filter.dimension = undefined;
            }
            if (filter.selectedDimension) {
              filter.selectedDimension = undefined;
            }
            return filter;
          });

          dispatch(updateEditorWidgetConfig("filters", newFilters, ownProps.widgetIndex));
        }
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension_label", label, ownProps.widgetIndex));
    },
    updateDimensionLabel: (event) => {
      dispatch(updateEditorWidgetConfig("dimension_label", event.target.value, ownProps.widgetIndex));
    },
    updateComparisonColumnName: (event) => {
      dispatch(
        updateEditorWidgetConfig(
          "secondary_column_is_previous_period_comparison",
          event.target.checked,
          ownProps.widgetIndex
        )
      );
    },
    updateComparisonColumnLabel: (event) => {
      dispatch(updateEditorWidgetConfig("comparison_column_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryLabel: (event) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = String(selected.label.split(" --")[0]).trim();
      }

      dispatch(updateEditorWidgetConfig("secondary_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_label", label, ownProps.widgetIndex));
    },
    updateThirdLabel: (event) => {
      dispatch(updateEditorWidgetConfig("third_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateThirdMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = String(selected.label.split(" --")[0]).trim();
      }

      dispatch(updateEditorWidgetConfig("third_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("third_metric_label", label, ownProps.widgetIndex));
    },
    updateLimit: (event) => {
      dispatch(updateEditorWidgetConfig("limit", event.target.value, ownProps.widgetIndex));
    },
    updateSortBy: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("sort_by", value, ownProps.widgetIndex));
    },
    updateSortByAscDesc: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("sort_by_asc_desc", value, ownProps.widgetIndex));
    },
      updateDateFilter: (ranges, isEnabledDateFilter) => {
      if(isEnabledDateFilter){
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }
      
    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
