import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FilterValidation from "../../../../helpers/filter_validation";
import GenericListEditor from "../../../../widgets/data/list_editor";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../account_selector";

const basicFilterOperators = [
  { value: "LIKE", label: "contains" },
  { value: "NOT LIKE", label: "does NOT contain" },
];
const dimensionsFilters = [{ label: "Posts", value: "share" }];
const dimensions = [
  { label: "Posts", value: "share" },
  { label: "Posts with images", value: "MW-share-with-images" },
];
const noSecondaryMetric = ["user-audience"];

const sortByOptions = [
  { label: "Publication date", value: "createdAt" },
  { label: "Dimension", value: "dimension" },
  { label: "Metric #1", value: "metric[0]" },
  { label: "Metric #2", value: "metric[1]" },
  { label: "Metric #3", value: "metric[2]" },
];

class ListEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      metrics: [],
    };
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (
      (this.props.sort_by == "metric[1]" && !this.props.secondary_metric_name) ||
      (this.props.sort_by == "metric[2]" && !this.props.third_metric_name)
    ) {
      errors.push(`Invalid "Sort By". You can't sort by a metric that is not set.`);
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, []));

    this.props.save();

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    const metrics = store.getState().widgetReducer.linkedInPagesMetricsForCanvas;
    let metricsList = [];
    metricsList = metrics.filter((metric) => metric.metricLevel.includes(this.props.dimension));

    return (
      <GenericListEditor
        {...this.props}
        name="LinkedIn Pages List"
        sortByOptions={sortByOptions}
        dimensions={dimensions}
        metricsList={metricsList}
        filterOperators={basicFilterOperators}
        filterDimensionList={dimensionsFilters}
        filterHasORenabled={true}
        noSecondaryMetric={noSecondaryMetric}
        saveAndClose={this.saveAndClose.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension_label", label, ownProps.widgetIndex));
    },
    updateDimensionLabel: (event) => {
      dispatch(updateEditorWidgetConfig("dimension_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetricLabel: (event) => {
      dispatch(updateEditorWidgetConfig("metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_label", label, ownProps.widgetIndex));
    },
    updateSecondaryLabel: (event) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("secondary_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_label", label, ownProps.widgetIndex));
    },
    updateThirdLabel: (event) => {
      dispatch(updateEditorWidgetConfig("third_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateThirdMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("third_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("third_metric_label", label, ownProps.widgetIndex));
    },
    updateLimit: (event) => {
      dispatch(updateEditorWidgetConfig("limit", event.target.value, ownProps.widgetIndex));
    },
    updateSortBy: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("sort_by", value, ownProps.widgetIndex));
    },
    updateSortByAscDesc: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("sort_by_asc_desc", value, ownProps.widgetIndex));
    },
    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
