import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/facebook.png";

export default class FacebookPages extends IntegrationBase {
  // This configures some autoloading
  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: true, dimensionLoaderCallback: this.formatDimensions.bind(this) },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-facebook_pages",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      dimensions: () => {
        return this.dimensions;
      },
      filterDimensionList: () => {
        return [
          { label: "Posts", value: "post" },
          { label: "Videos", value: "video" },
        ];
      },
      filterOperators: [
        { value: "LIKE", label: "contains" },
        { value: "NOT LIKE", label: "does NOT contain" },
      ],
      filterHasORenabled: true,
    };
  }

  // The name of this function is important. It's a hardcoded callback name used to autoload metrics.
  // This function is used to properly format the metrics returned for the integration, into the generic format.
  formatMetrics(metrics) {
    let formattedMetrics = [];

    for (let metric in metrics) {
      let label = `${metrics[metric].label}`;
      if (String(metrics[metric].description).trim() != "") {
        label = `${label} -- ${metrics[metric].description}`;
      }

      formattedMetrics.push({
        value: metric,
        label: label,
        valid_dimensions: metrics[metric].metric_level,
      });
    }

    this.metrics = formattedMetrics;
  }

  formatAccounts(accounts) {
    let options = [];
    for (let index = 0; index < accounts.length; index++) {
      const integrationAccount = accounts[index];
      let providerAccounts = integrationAccount.attributes.provider_accounts;
      if (
        integrationAccount.type == this.integrationConfig().accountSelector.databaseProviderType &&
        providerAccounts
      ) {
        for (let j = 0; j < providerAccounts.length; j++) {
          options.push({
            value: providerAccounts[j].id,
            label: providerAccounts[j].name,
          });
        }
      }
    }

    store.dispatch(setIntegrationAccountList(this.oAuthProviderName(), options));
  }

  name() {
    return "Facebook Pages";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "facebook";
  }

  integrationFor() {
    return ["report"];
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: false,
        // filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        widgetName: "Facebook Pages Single Metric",
        widgetType: "facebook-pages-single-metric",
        defaultLabel: "Page fans",
        defaultMetricName: "page_fans",
        valid_dimensions: ["page"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        // filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        filterByCurrentDimensionOnly: true,
        sortEnabled: true, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        sortByOptions: [
          // this is to overwrite the defaults for sort options
          { label: "Publication date", value: "timestamp" },
          { label: "Dimension", value: "dimension" },
          { label: "Metric #1", value: "metric[0]" },
          { label: "Metric #2", value: "metric[1]" },
          { label: "Metric #3", value: "metric[2]" },
        ],
        widgetName: "Facebook Pages List",
        widgetType: "facebook-pages-list",
        defaultLabel: "Posts with the most reactions",
        defaultDimensionName: "post",
        defaultDimensionLabel: "Posts",
        defaultMetricName: "MW-total_reactions",
        defaultMetricLabel: "Total reactions",
        // noSecondaryMetric: [],
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        // filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        filterByCurrentDimensionOnly: true,
        widgetName: "Facebook Pages Pie Chart",
        widgetType: "facebook-pages-pie-chart",
        // defaultLabel: "Browsers",
        defaultLabel: "Fans' countries",
        defaultMetricName: "page_fans_country",
        defaultDimensionName: "MW-page-user-demographics",
        // defaultDimensionName: "browser",
        // defaultDimensionLabel: "Browser",
        // defaultMetricName: "sessions",
        // defaultMetricLabel: "Sessions",
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        // filtersViaAPI: true, // The default is undefined or true, and it's when we use the API to filter. THe alternative is filterint on our end with the data we have.
        filterByCurrentDimensionOnly: true,
        filterOnPeriodEnabled: false, // default: true
        isPeriodDimensionFilterable: true,
        widgetName: "Facebook Pages Bar Graph",
        widgetType: "facebook-pages-bar-graph",
        defaultLabel: "Followers trend",
        defaultDimensionName: "MW--period",
        defaultMetricName: "page_fans",
        defaultMetricLabel: "Fans / Followers",
        // valid_dimensions: ["dimension for which we should metrics here"], // This is optional. Needs to be an array. If it is not provided, all metrics will be available.
        valid_dimensions__for_period: ["page"], // what is the dimension for Single Metrics? Put it here. Supports multiple dimensions here
      },
    };
  }
}
