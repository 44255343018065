import React from "react";
var createReactClass = require("create-react-class");
import { connect, Provider } from "react-redux";
import ReactGridLayout from "react-grid-layout";
import $ from "jquery";
import Select from "react-select";

import WidgetButton from "../components/utility/widget_button";
import AllIntegrationsDrawer from "../components/reports/integration-drawers/all-integrations.jsx";
import Modal from "../components/modal";
import APIError from "../helpers/api_error";

import Button from "./generic/button.js";

import { reportFormValidation } from "../helpers/report_form_validation";
import GoogleSheetsAccountLoader from "../integrations/google_sheets/account_loader";

import ButtonWithIcon from "./generic/button_with_icon.js";
import ReportPreviewFetcher from "../helpers/widget_preview/report_preview_fetcher";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
  subDays,
  addMonths,
  format,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  differenceInDays,
  subYears,
  subMonths,
} from "date-fns";
import Cookies from "js-cookie";
import { frCA, enUS } from "date-fns/locale";
import reportAPI from "../helpers/reportUpdateAPI.js";
import { IntegrationAccountsModal } from "./integration_accounts_modal.jsx";
// We must initialize this, otherwise if the first drag we do is NOT with a new widget we add, the onDrag of the grid will crash.
window.currentlyDraggedElement = {};

// We want to know if we are currently adding a widget via drag & drop
window.isCurrentlyAddingWidget = false;

function mapReportCanvasStateToProps(state, ownProps) {
  return state.widgetReducer;
}

let showNoAccountModal = undefined; // This is a hack. It serves as a proxy to the same function from within the React component.
const validateWidgetHasAccountConfigured = function (widgetName) {
  if (reportFormValidation.widgetHasNoAccountConfigured(widgetName)) {
    let integrationCode = reportFormValidation.getIntegrationByWidgetName(widgetName);
    let integrationName = integrationStore.findIntegrationByOAuthProviderName(integrationCode).name();
    showNoAccountModal(integrationName);
    return false;
  }

  return true;
};

class Modal_NoAccountAvailable extends React.Component {
  close() {
    this.props.closeCallback();
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={`No ${this.props.integrationName} account available`}
        htmlMessage={
          <span>
            <p className="mt-2">
              You do not have any {this.props.integrationName} account available in your account. You probably did not
              connect this integration yet.
            </p>
            <p className="mt-2">
              <strong>What does this mean?</strong>
            </p>
            <p className="mt-2">
              You will not be able to save this report unless you remove all your {this.props.integrationName} widgets.
            </p>
            <p className="mt-2">
              You should remove those widgets, save your report and then after head in the Integrations section, to
              connect your account before getting back to this report and configure it as you wish.
            </p>
          </span>
        }
        closeCallback={() => this.close()}
      />
    );
  }
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    if (Cookies.get("reportStartDate") === undefined || Cookies.get("reportStartDate") === "Invalid Date") {
      this.state = {
        startDate: subMonths(new Date(), 1),
        endDate: subDays(new Date(), 1),
      };
    } else {
      this.state = {
        startDate: new Date(Cookies.get("reportStartDate")),
        endDate: new Date(Cookies.get("reportEndDate")),
      };
    }
  }
  close() {
    const requiredIntegrations = reportFormValidation.requiredIntegrations();

    // Validating integrations with max 90 days of data at a time
    const integrationsWithMax90days = ["facebook"];
    const daysInRange = differenceInDays(this.state.endDate, this.state.startDate);
    const hasMax90daysIntegration = integrationsWithMax90days.some((val) => requiredIntegrations.includes(val));
    if (hasMax90daysIntegration && daysInRange > 90) {
      this.setState({
        modalError: `Reports with widgets from Facebook can not have more than 90 days of data. This is a limitation from Facebook. Your date range has ${daysInRange} days. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 2 years ago in the data (from current date)
    const integrationsWithMax2yearsOfHistory = ["facebook", "instagram"];
    const date2yearsAgo = subYears(new Date(), 2);
    const haxMax2yearsIntegration = integrationsWithMax2yearsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (haxMax2yearsIntegration && this.state.startDate < date2yearsAgo) {
      this.setState({
        modalError: `Reports with widgets from Facebook and/or Instagram can not include data from more than 2 years ago. This is a limitation from Facebook & Instagram. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 37 months ago in the data (from current date)
    const integrationsWithMax37MonthsOfHistory = ["facebookads"];
    const date37monthsAgo = subMonths(new Date(), 37);
    const hasMax37monthsIntegration = integrationsWithMax37MonthsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (hasMax37monthsIntegration && this.state.startDate < date37monthsAgo) {
      this.setState({
        modalError: `Reports with widgets from Facebook Ads can not include data from more than 37 months ago. This is a limitation from Facebook. You can change the dates and try sending it again.`,
      });
      return;
    }

    // Validating integrations that can't go further than 14 months
    const integrationsWithMax14MonthsOfHistory = ["linkedin-pages"];
    const date14monthsAgo = subMonths(new Date(), 14);
    const hasMax14monthsIntegration = integrationsWithMax14MonthsOfHistory.some((val) =>
      requiredIntegrations.includes(val)
    );
    if (hasMax14monthsIntegration && this.state.startDate < date14monthsAgo) {
      this.setState({
        modalError: `Reports with widgets from LinkedIn Pages can not include data from more than 14 months ago. This is a limitation from LinkedIn. You can change the dates and try sending it again.`,
      });
      return;
    }

    this.setState({
      modalError: undefined,
    });

    Cookies.set("reportStartDate", this.state.startDate, { expires: 7 });
    Cookies.set("reportEndDate", this.state.endDate, { expires: 7 });

    this.props.closeCallback();
  }

  onDateRangeInputChange(val) {
    this.setState({ startDate: val.selection.startDate });
    this.setState({ endDate: val.selection.endDate });
  }

  render() {
    return (
      <Modal visible={this.props.visible} title="Select date range" widthCanBeLarge closeCallback={() => this.close()}>
        {this.state.modalError && <p className="mt-4 text-red-800">{this.state.modalError}</p>}
        <DateRangePicker
          locale={this.props.lang == "fr" ? frCA : enUS}
          ranges={[
            {
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              key: "selection",
            },
          ]}
          inputRanges={[]}
          staticRanges={defaultStaticRanges}
          months={2}
          direction="horizontal"
          scroll={{ enabled: true }}
          maxDate={subDays(new Date(), 1)}
          onInit={this.onDateRangeInputChange.bind(this)}
          onChange={this.onDateRangeInputChange.bind(this)}
        />
        <div></div>
      </Modal>
    );
  }
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: "Yesterday",
    range: () => ({
      startDate: subDays(new Date(), 1),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: startOfWeek(subDays(new Date(), 7)),
      endDate: endOfWeek(subDays(new Date(), 7)),
    }),
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  {
    label: "Last 30 days",
    range: () => ({
      startDate: subDays(new Date(), 30),
      endDate: subDays(new Date(), 1),
    }),
  },
  {
    label: "Last 90 days",
    range: () => ({
      startDate: subDays(new Date(), 90),
      endDate: subDays(new Date(), 1),
    }),
  },
]);

var ReportCanvas = createReactClass({
  getInitialState: function () {
    return {
      name: "",
      whichWidgetEditorModalOpen: -1,
      canvasID: undefined,
      gridElement: undefined,
      noAccountModal_isOpen: false,
      canvasWidth: 720, // default grid width
      widgetsDrawerCollapsed: true,
      reportTemplates: [],
      selectedTemplate: "",
      confirmClearingReport: false,
      showLayoutWidget: true,
      showIntegrationWidgets: true,
      showDisconnectedIntegrationWidgets: false,
      modalOpen: false,
      isReportTemplateLoading: true,
      enable_integration_modal: true,
    };
  },
  setSelectedTemplate: function (key) {
    if (!key) this.setState({ selectedTemplate: "" });
    this.setState({ selectedTemplate: key });
  },
  triggerReportClearingWarning: function () {
    this.setState({ confirmClearingReport: true });
    setTimeout(() => {
      this.setState({ confirmClearingReport: false });
    }, 4000);
  },
  toggleDrawerWidget: function () {
    this.setState({
      widgetsDrawerCollapsed: !this.state.widgetsDrawerCollapsed,
    });
  },
  updateCanvasWidth: function () {
    if (this.canvasRef && this.canvasRef.clientWidth > 0) {
      this.setState({ canvasWidth: Math.min(this.canvasRef.clientWidth, 780) });
    }
  },
  getWidgetComponent: function (widgetIndex) {
    let widgetConfig = store.getState().widgetReducer.widgetConfigs[widgetIndex];

    // Why could it not be there yet? While drag & dropping
    if (!widgetConfig) {
      widgetConfig = window.currentlyDraggedElement.config;
    }

    var widgetType = widgetConfig.widgetType;

    // All widgets come from the integration store.
    var widgetName = window.integrationStore.widgetTypeToNameStore[widgetType];
    if (widgetName) {
      var widget = window.integrationStore.getWidgetByName(widgetName);
      return widget.widgetComponent(widgetIndex);
    }
  },
  getWidgetComponentEditor: function (widgetIndex, closeAction, deleteWidget) {
    var widgetType = store.getState().widgetReducer.widgetConfigs[widgetIndex].widgetType;

    // Is it in the integration store?
    var widgetName = window.integrationStore.widgetTypeToNameStore[widgetType];
    if (widgetName) {
      var widget = window.integrationStore.getWidgetByName(widgetName);
      return widget.editorComponent(widgetIndex, closeAction, deleteWidget);
    }

    // Is it a layout widget?
    var widget = window.layoutWidgetStore.getWidgetByType(widgetType);
    if (widget) {
      return widget.editorComponent(widgetIndex, closeAction, deleteWidget);
    }
  },
  loadMailchimpMetrics: function () {
    $.ajax({
      url: "/api/v1/metrics?provider=mailchimp",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setMailchimpMetricsForCanvasCache(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("DefaultGAMetrics", xhr, status, err);
      }.bind(this),
    });
  },
  loadReportCanvasGaMetrics: function () {
    $.ajax({
      url: "/api/v1/metrics?provider=ga",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setGaMetricsForCanvasCache(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("DefaultGAMetrics", xhr, status, err);
      }.bind(this),
    });
  },
  loadGoogleAdsMetrics: function () {
    $.ajax({
      url: "/api/v1/metrics?provider=googleads",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setGoogleAdsMetricsForCanvasCache(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("GoogleAdsMetrics", xhr, status, err);
      }.bind(this),
    });
  },
  loadLinkedInAdsMetrics: function () {
    $.ajax({
      url: "/api/v1/metrics?provider=linkedin-ads",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setLinkedINMetricsForCanvasCache(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("LinkedInAdsMetrics", xhr, status, err);
      }.bind(this),
    });
  },
  loadLinkedInPagesMetrics: function () {
    $.ajax({
      url: "/api/v1/metrics?provider=linkedin-pages",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setLinkedINPagesMetricsForCanvasCache(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("LinkedInPagesMetrics", xhr, status, err);
      }.bind(this),
    });
  },
  loadIntegrations: function () {
    $.ajax({
      url: "/api/v1/integrations",
      dataType: "json",
      method: "GET",
      success: function (data) {
        store.dispatch(setIntegrations(data));
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("integrations", xhr, status, err);
      }.bind(this),
    });
  },
  loadReportTemplates: function () {
    this.setState({ isReportTemplateLoading: true });
    $.ajax({
      url: "/api/v1/templates",
      dataType: "json",
      method: "GET",
      success: function ({ data }) {
        this.setState({ reportTemplates: data, isReportTemplateLoading: false });
      }.bind(this),
      error: function (xhr, status, err) {
        this.setState({ isReportTemplateLoading: false });
        new APIError("reportTemplates", xhr, status, err);
      }.bind(this),
    });
  },

  componentDidMount: function () {
    showNoAccountModal = this.showNoAccountModal; // This is a hack. It serves as a proxy to the same function from within the React component.
    this.setState({ gridElement: document.getElementsByClassName("canvas-grid")[0] });
    this.updateCanvasWidth();
    window.addEventListener("resize", this.updateCanvasWidth);
    document.addEventListener("visibilitychange", this.updateCanvasWidth, false);
    this.loadMailchimpMetrics();
    this.loadReportCanvasGaMetrics();
    this.loadGoogleAdsMetrics();
    this.loadLinkedInAdsMetrics();
    this.loadLinkedInPagesMetrics();
    this.loadIntegrations();
    this.loadReportTemplates();
    new GoogleSheetsAccountLoader().loadAccounts();

    this.storeSubscription = store.subscribe(this.handleStoreUpdates);
  },
  
    
 componentDidUpdate: function (prevProps, prevState) {
    const widgetLayout = store.getState().widgetReducer.widgetLayouts;
    const missingIntegrations = reportFormValidation.missingAccounts(store.getState().reportFormReducer);
    let enable_integration_modal = store.getState().reportFormReducer.enable_integration_modal;

    if ((widgetLayout !== prevProps.widgetLayouts) ||
      enable_integration_modal !== this.state.enable_integration_modal) {
    
        this.setState({ enable_integration_modal }, () => {
        if (widgetLayout.length > 0 && enable_integration_modal) {
            this.updateReport();
        }
        if (widgetLayout.length > 0 && missingIntegrations?.warnings.length > 0 && !this.state.isReportTemplateLoading) {
          this.showIntegrationModal(enable_integration_modal);
        } else {
          store.dispatch(setReportDraft(false));
          store.dispatch(setFlashMessageWarning("", [""], ""));
        }
      });
    }
  },


 componentWillUnmount: function () {
    window.removeEventListener("resize", this.updateCanvasWidth);
    document.removeEventListener("visibilitychange", this.updateCanvasWidth, false);
    this.storeSubscription();
  },

  handleStoreUpdates: function () {
    let newValue = store.getState().widgetReducer.editorWidgetID;
    if (this.state.whichWidgetEditorModalOpen !== newValue) {
      this.setState({ whichWidgetEditorModalOpen: newValue });
    }
  },
  updateReport: async function () { 
    store.dispatch(setReportDraft(true));
    await reportAPI.updateToAPI();
  },
   showIntegrationModal: async function (enable_integration_modal) {
    if (enable_integration_modal) {
        store.dispatch(setReportDraft(true));
        await reportAPI.updateToAPI();
        this.setState({ modalOpen: true });
        const missingIntegrations = reportFormValidation.missingAccounts(store.getState().reportFormReducer);
        store.dispatch(setFlashMessageWarning("Warning", [`The following integrations are missing: ${missingIntegrations.warnings.join(", ")}`], "Integrations"));
    }
  },

  showWidgetEditModal: function (index) {
    store.dispatch(saveWidgetStateBeforeEdit(index));
  },

  closeWidgetEditModal: function () {
    store.dispatch(closeWidgetEditor());
  },

  deleteSelectedWidget: function (widgetId) {
    if (confirm("Are you sure you want to delete this widget?")) {
      this.closeWidgetEditModal();
      store.dispatch(removeWidget(widgetId));
    }
  },

  addWidget: function (widgetName, chartType) {
    if (!validateWidgetHasAccountConfigured(widgetName)) {
      return;
    }

    store.dispatch(addWidgetOnCanvas(widgetName, chartType));

    analytics.track("Widget added", {
      method: "click",
      widgetName: widgetName,
    });
  },

  showNoAccountModal: function (integration) {
    this.setState({
      noAccountModal_isOpen: true,
      noAccountModal_integrationName: integration,
    });
  },

  duplicateWidget: function (widgetId) {
    store.dispatch(duplicateWidgetOnCanvas(widgetId));
  },

  clearReport: function () {
    this.setState({ confirmClearingReport: false });
    store
      .getState()
      .widgetReducer.widgetLayouts.map(({ i }) => i)
      .forEach((id) => store.dispatch(removeWidget(id)));
  },

  openDatePicker: function () {
    this.setState({
      datePickerModal_isOpen: true,
    });
  },

  loadSelectedTemplate: function () {
    const templateData = this.state.reportTemplates.find((t) => t.attributes.key == this.state.selectedTemplate);
    if (templateData == undefined) return;

    const { widgets } = templateData.attributes;
    const widgetLayouts = widgets.map(({ widgetLayout }) => widgetLayout);

    // Configs is funkier: Each an object property named after the widget ID
    store.dispatch(setLayout(widgetLayouts));
    widgets.forEach((w) => {
      let widgetIndex = w.widgetLayout.i;
      for (let prop in w) {
        store.dispatch(updateWidgetConfig(prop, w[prop], widgetIndex));
      }
    });
  },

  componentWillReceiveProps: function (nextProps) {
    this.setState({ name: nextProps.name });
  },

  formatedDate: function (widgetConfig) {
    if (widgetConfig?.date_filter_by_widget) {
      const newDateStart = new Date(widgetConfig.date_filter_by_widget?.startDate).toLocaleDateString() ?? "";
      const newDateEndD = new Date(widgetConfig.date_filter_by_widget?.endDate).toLocaleDateString() ?? "";
      const formatedDate = `${newDateStart} - ${newDateEndD}`;
      return formatedDate;
    } else {
      return "";
    }
  },
  closeModal: function () {
    this.setState({ modalOpen: false });
  },

  render: function () {
    const storeState = store.getState();
    var isEditorModalOpen = this.state.whichWidgetEditorModalOpen !== -1;
    var canvasIsEmpty = storeState.widgetReducer.widgetLayouts.length === 0;

    const betaBadge = (
      <span className="inline-flex items-center px-2 py-0.5 ml-2 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800">
        beta
      </span>
    );

    var startDate = new Date(Cookies.get("reportStartDate"));
    var endDate = new Date(Cookies.get("reportEndDate"));

    if (Cookies.get("reportStartDate") === undefined || Cookies.get("reportStartDate") === "Invalid Date") {
      startDate = subMonths(new Date(), 1);
      endDate = subDays(new Date(), 1);
      Cookies.set("reportStartDate", startDate, { expires: 7 });
      Cookies.set("reportEndDate", endDate, { expires: 7 });
    }

    const selectedDate =
      "Selected date between " +
      startDate.toISOString().split("T")[0] +
      " and " +
      endDate.toISOString().split("T")[0] +
      " ";
    return (
      <div>
        <div className="tw-canvas-container">
          <div className="tw-canvas-side">
            {!this.state.isReportTemplateLoading && <IntegrationAccountsModal closeModal={this.closeModal} modalOpen={this.state.modalOpen} />}
            <Modal_NoAccountAvailable
              visible={this.state.noAccountModal_isOpen}
              integrationName={this.state.noAccountModal_integrationName}
              closeCallback={function () {
                this.setState({ noAccountModal_isOpen: false });
              }.bind(this)}
            />
            <DatePicker
              className="max-w-lg"
              visible={this.state.datePickerModal_isOpen}
              integrationName={this.state.noAccountModal_integrationName}
              reportTemplates={this.state.reportTemplates}
              closeCallback={function () {
                new ReportPreviewFetcher().fetch();
                this.setState({ datePickerModal_isOpen: false });
              }.bind(this)}
            />
            <div className="grid grid-cols-2">
              <h3 className="tw-medium-header my-2 py-2">
                Report layout
                {!canvasIsEmpty && (
                  <button
                    className={
                      (this.state.confirmClearingReport ? "text-red-700" : "") +
                      " ml-2 hover:bg-red-100 rounded py0.5 px-2"
                    }
                    onClick={this.state.confirmClearingReport ? this.clearReport : this.triggerReportClearingWarning}
                  >
                    {this.state.confirmClearingReport ? "Click again to confirm" : "Clear all"}
                  </button>
                )}
              </h3>
              <h3 className={"italic normal-case font-light tw-medium-header my-1 py-1 text-right"}>
                {selectedDate}
                <ButtonWithIcon
                  icon="fa-calendar-alt"
                  className={"tw-button align-center px-2 py-0 m-7"}
                  onClick={this.openDatePicker}
                ></ButtonWithIcon>
              </h3>
            </div>

            {canvasIsEmpty && (
              <div className="mx-auto max-w-sm mb-8">
                <p className="italic my-4 text-sm text-gray-700">
                  This report is empty! You can start by adding widgets, or from one of our pre-built templates if you’d
                  like:
                </p>
                <div className="flex flex-row">
                  <Select
                    name="Template"
                    className="flex-grow mr-2"
                    placeholder="Select a template..."
                    options={this.state.reportTemplates.map((r) => ({
                      label: r.attributes.name,
                      value: r.attributes.key,
                    }))}
                    value={this.state.selectedTemplate}
                    onChange={({ value }) => {
                      this.setSelectedTemplate(value);
                    }}
                    clearable={false}
                  />
                  {this.state.selectedTemplate !== "" && (
                    <Button text="Apply template" onClick={this.loadSelectedTemplate} />
                  )}
                </div>
              </div>
            )}
            <div
              id="canvas"
              ref={(c) => {
                this.canvasRef = c;
              }}
            >
              <Modal noDefaultButtons widthCanBeLarge visible={isEditorModalOpen} closeCallback={() => { }}>
                <div>
                  {isEditorModalOpen
                    ? this.getWidgetComponentEditor(
                      this.state.whichWidgetEditorModalOpen,
                      this.closeWidgetEditModal,
                      function () {
                        return this.deleteSelectedWidget(this.state.whichWidgetEditorModalOpen);
                      }.bind(this)
                    )
                    : false}
                </div>
              </Modal>

              <ReactGridLayout
                className="canvas-grid transition-all border border-gray-300 rounded-lg "
                style={{ minHeight: "200px" }}
                draggableCancel="input,textarea"
                margin={[8, 8]}
                cols={3}
                rowHeight={72}
                width={this.state.canvasWidth}
                droppingItem={{
                  i: "__dropping-elem__",
                  w: 1,
                  h: 1,
                }} /* Configuring the value of `i` specifically here, because we rely on it. If the library changes its default value, we will at least have it set here and blow up immediately when testing (manually) the drag & drop flow */
                // layout is required so that if we change the layout from outside of this widget (ex: in the reducer, which we do right now), this
                // is not reflected in the grid here in some cases. With this, we fixed the last known issue we had with drag & drop of outside elements
                layout={storeState.widgetReducer.widgetLayouts}
                isDroppable={true}
                onLayoutChange={function (newLayout) {
                  // If the layout changed, but it's not due to a new widget, then we want to update the layout.
                  // For example, if you have 3 single metrics on one line, and one on the second line, and then remove
                  // the one above the second line widget, the second line widget will be bumped to the first line.
                  // Before having this code, the line number (the "y" value) was not changed in the widget layout,
                  // and not being rendered in the email as seen in the report builder.
                  if (!window.isCurrentlyAddingWidget) {
                    store.dispatch(updateWidgetsLayout(newLayout));
                  }
                }}
                onDragStart={function (__, draggedWidget) {
                  // If this is a new widget, we set its width here (otherwise, it needs to be hardcoded for the grid, not per widget type)
                  // As we have widgets that are 1 or 3 wide, we need to change it here based on what we are dragging.
                  if (draggedWidget.i === "__dropping-elem__") {
                    draggedWidget.w = window.currentlyDraggedElement.layout.w;
                    draggedWidget.h = window.currentlyDraggedElement.layout.h;
                  }
                }}
                onDrag={function (newFullLayout) {
                  window.currentlyDraggedElement.newFullLayout = newFullLayout;
                }}
                onDragStop={function (newFullLayout) {
                  store.dispatch(setLayout(newFullLayout));
                }}
                onDrop={(newWidget) => {
                  if (!validateWidgetHasAccountConfigured(window.currentlyDraggedElement.widgetName)) {
                    return;
                  }

                  if (!this.state.gridElement) return;
                  // Disable the drag status on grid
                  this.state.gridElement.classList.remove("new-widget-drag-active");

                  let layout = window.currentlyDraggedElement.layout;
                  layout.x = newWidget.x;
                  layout.y = newWidget.y;

                  store.dispatch(
                    setCanvasDroppingItem(
                      layout,
                      window.currentlyDraggedElement.config,
                      window.currentlyDraggedElement.newFullLayout
                    )
                  );

                  // We are done adding a widget, so we disable this bool
                  window.isCurrentlyAddingWidget = false;

                  analytics.track("Widget added", {
                    method: "drag & drop",
                    widgetName: window.currentlyDraggedElement.widgetName,
                  });
                }}
              >
                {this.props.widgetLayouts
                  .filter((widgetLayout) => widgetLayout.i !== "__dropping-elem__")
                  .map(
                    function (widgetLayout) {
                      const widgetConfig = storeState.widgetReducer.widgetConfigs[widgetLayout.i];
                      let widgetType;
                      let widgetHeight = undefined;
                      let editorAvailable = true;
                      let duplicateAvailable = true;
                      let dateFormatView = "";
                      const enabeleDateFeature = store.getState().reportFormReducer.enbale_widget_date_feature
                      if (widgetConfig) {
                        widgetType = widgetConfig.widgetType;
                        dateFormatView = this.formatedDate(widgetConfig);
                        // If it's a layout widget
                        const layoutWidget = window.layoutWidgetStore.getWidgetByType(widgetConfig.widgetType);
                        if (layoutWidget) {
                          widgetLayout.h = layoutWidget.height();
                          widgetLayout.minH = layoutWidget.height();
                          widgetLayout.maxH = layoutWidget.height();

                          return layoutWidget.renderInGrid(
                            widgetLayout,
                            this.showWidgetEditModal,
                            this.deleteSelectedWidget
                          );
                        }

                        const integrationWidget = window.integrationStore.getWidgetByType(widgetConfig.widgetType);

                        if (widgetConfig.chartType === undefined && widgetConfig.limit > 4) {
                          // For the list, if the limit is over 4, we put a height for display 10 lines max.
                          widgetHeight = 5;
                        } else if (integrationWidget) {
                          widgetHeight = integrationWidget.height();
                          if (widgetConfig.noEditor) {
                            editorAvailable = false;
                          }
                          if (widgetConfig.unique) {
                            duplicateAvailable = false;
                          }
                        }
                      }

                      // Other wise, all integration widgets are taken care of below.
                      const minHeight = 1,
                        defaultHeight = 2,
                        maxHeight = 6;

                      widgetLayout.minH = minHeight;
                      widgetLayout.maxH = maxHeight;
                      widgetLayout.h = widgetHeight || defaultHeight;

                      return (
                        <div
                          className="canvas-grid-item relative"
                          id={widgetLayout.i}
                          key={widgetLayout.i}
                          data-grid={widgetLayout}
                        >

                          <div className="canvas-widget-edit-save">
                            {enabeleDateFeature && <p className="text-xs font-bold pt-1 px-1 text-center">{dateFormatView}</p>}
                            {duplicateAvailable && (
                              <a
                                onClick={function () {
                                  return this.duplicateWidget(widgetLayout.i);
                                }.bind(this)}
                                className=""
                              >
                                <i className="fas fa-copy" />
                              </a>
                            )}
                            {editorAvailable && (
                              <a
                                onClick={function () {
                                  return this.showWidgetEditModal(widgetLayout.i);
                                }.bind(this)}
                                className=""
                              >
                                <i className="fas fa-edit" />
                              </a>
                            )}
                            <a
                              onClick={function () {
                                return this.deleteSelectedWidget(widgetLayout.i);
                              }.bind(this)}
                              className=""
                            >
                              <i className="fas fa-times" />
                            </a>
                          </div>
                          {this.getWidgetComponent(widgetLayout.i)}
                        </div>
                      );
                    }.bind(this)
                  )}
              </ReactGridLayout>
            </div>
          </div>

          <div className="tw-widgets-side z-10 pointer-events-none">
            <h3 className="flex tw-medium-header my-2 pointer-events-auto">
              <span className="ml-auto mr-4 mb-2 lg:ml-0 lg:mr-auto">Add widgets</span>
              <button
                className={
                  "tw-button flex align-center px-2 py-0 lg:hidden -mt-2 " +
                  (this.state.widgetsDrawerCollapsed
                    ? "bg-blue-700 hover:bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-800")
                }
                onClick={this.toggleDrawerWidget}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width="24"
                  className={
                    "my-auto transform transition-transform " +
                    (this.state.widgetsDrawerCollapsed ? "rotate-0" : "rotate-45")
                  }
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
              </button>
            </h3>
            <div
              className={
                "tw-collapsible-widgets absolute right-0 -mt-1 lg:mt-0 pointer-events-auto rounded" +
                (this.state.widgetsDrawerCollapsed ? " hidden lg:block" : " block")
              }
            >
              <div className="relative">
                <div className="tw-widgets-container border border-gray-200 rounded">
                  <h3
                    className="tw-medium-header text-small text-center mt-4 mb-2 mx-2"
                    onClick={() => this.setState({ showLayoutWidget: !this.state.showLayoutWidget })}
                  >
                    Layout{" "}
                    <span className="float-right">
                      <i
                        className={`fas fa-angle-${
                          !this.state.showLayoutWidget ? "down" : "up"
                        } ml-2 text-blue-mw ml-4 is-flipped`}
                      ></i>
                    </span>
                  </h3>
                  {this.state.showLayoutWidget && (
                    <div className="tw-widget-box flex flex-col" id="layout-widgets-box">
                      <WidgetButton
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                        storeState={storeState}
                        label="Separator"
                        widgetName="Layout -- Separator"
                        icon="Separator"
                      />
                      <WidgetButton
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                        storeState={storeState}
                        label="Title"
                        widgetName="Layout -- Title"
                        icon="Title"
                      />
                      <WidgetButton
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                        storeState={storeState}
                        label="Rich Text"
                        widgetName="Layout -- Rich Text"
                        icon="Text"
                      />
                      <WidgetButton
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                        storeState={storeState}
                        label="Basic Text & HTML"
                        widgetName="Layout -- Large Text Box"
                        icon="Text"
                      />
                      <WidgetButton
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                        storeState={storeState}
                        label="Page Break (PDF)"
                        widgetName="Layout -- PDF Page Break"
                        icon="Text"
                      />
                    </div>
                  )}

                  <h3
                    className="tw-medium-header text-small text-center my-4 mb-2 mx-2"
                    onClick={() => this.setState({ showIntegrationWidgets: !this.state.showIntegrationWidgets })}
                  >
                    Data widgets{" "}
                    <span className="float-right">
                      <i
                        className={`fas fa-angle-${
                          !this.state.showIntegrationWidgets ? "down" : "up"
                        } ml-2 text-blue-mw ml-4 is-flipped`}
                      ></i>
                    </span>
                  </h3>

                  {this.state.showIntegrationWidgets && (
                    <div
                      className="tw-widget-box flex flex-col"
                      id="integrations-widgets-box"
                    >
                      <AllIntegrationsDrawer
                        connected={true}
                        storeState={storeState}
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                      />
                    </div>
                  )}

                  <h3
                    className="tw-medium-header text-small text-center my-4 mb-2 mx-2"
                    onClick={() =>
                      this.setState({
                        showDisconnectedIntegrationWidgets: !this.state.showDisconnectedIntegrationWidgets,
                      })
                    }
                  >
                    Not Connected{" "}
                    <span className="float-right">
                      <i
                        className={`fas fa-angle-${
                          !this.state.showDisconnectedIntegrationWidgets ? "down" : "up"
                        } ml-2 text-blue-mw ml-4 is-flipped`}
                      ></i>
                    </span>
                  </h3>

                  {this.state.showDisconnectedIntegrationWidgets && (
                    <div
                      className="tw-widget-box flex flex-col"
                      id="integrations-widgets-box"
                    >
                      <p className="text-sm text-gray-600 p-2">
                        You did not connect accounts for those integrations. To enable them, you need to{" "}
                        <a href="/providers" target="_blank" className="underline text-blue-500">
                          connect at least one account here.
                        </a>
                      </p>
                      <AllIntegrationsDrawer
                        connected={false}
                        storeState={storeState}
                        gridElement={this.state.gridElement}
                        addWidget={this.addWidget}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

var ReportCanvasConnect = connect(mapReportCanvasStateToProps, null, null, {})(ReportCanvas);
var ReportCanvasWithProvider = createReactClass({
  render: function () {
    return (
      <Provider store={store}>
        <ReportCanvasConnect />
      </Provider>
    );
  },
});

export { ReportCanvas };
export default ReportCanvasWithProvider;
