import React from "react";
var createReactClass = require("create-react-class");
import Select from "react-select";
import $ from "jquery";
import APIError from "../../helpers/api_error";
import { reportFormValidation } from "../../helpers/report_form_validation";

const LinkedInPagesAccountSelector = createReactClass({
  apiURL: function () {
    return "/api/v1/provider_accounts";
  },

  onChange: function (data) {
    let val = undefined;
    if (data) {
      val = data.value;
    }

    this.setState({ linkedInAccount: val });

    if (this.props.disconnectStore) {
      this.props.onChange(data);
    } else {
      store.dispatch(setLinkedInPagesAccount(val));
    }
  },

  getInitialState: function () {
    return {
      linkedInAccount: undefined,
      modalVisible: false,
    };
  },

  componentDidMount: function () {
    this.loadLinkedInPagesAccounts();
    if (this.props.disconnectStore) {
      this.setState({ linkedInAccount: this.props.value });
    } else {
      this.storeSubscription = store.subscribe(
        function () {
          let newValue = store.getState().reportFormReducer.linkedin_pages_account_id;
          if (this.state.linkedInAccount !== newValue) {
            this.setState({ linkedInAccount: newValue });
          }
        }.bind(this)
      );
    }
  },

  componentWillUnmount: function () {
    if (!this.props.disconnectStore) {
      this.storeSubscription();
    }
  },

  componentWillReceiveProps: function (nextProps) {
    if (this.props.disconnectStore) {
      this.setState({ linkedInAccount: nextProps.value });
    }
  },

  loadLinkedInPagesAccounts: function () {
    $.ajax({
      url: this.apiURL(),
      dataType: "json",
      cache: false,
      success: function (data) {
        this.prepareOptions(data);
      }.bind(this),
      error: function (xhr, status, err) {
        new APIError("LinkedInPagesAccounts", xhr, status, err);
      }.bind(this),
    });
  },

  prepareOptions: function (data) {
    var options = [];
    for (var i = 0; i < data.data.length; i++) {
      var provider = data.data[i];
      if (
        provider.type == "metrics_providers-linked_in_pages" &&
        Array.isArray(provider.attributes.provider_accounts)
      ) {
        for (var j = 0; j < provider.attributes.provider_accounts.length; j++) {
          var acc = provider.attributes.provider_accounts[j];
          let name = acc.name;
          let accouuntId = String(acc.id);
          if (name == null) {
            name = `(No name available for account ${accouuntId} - you probably have insuffice access rights)`;
          }

          options.push({ value: accouuntId, label: name });
        }
      }
    }

    store.dispatch(setIntegrationAccountList("linkedin-pages", options));
  },

  render: function () {
    var tabIndex = this.props.tabIndex || "0";

    let display = "none";
    if (reportFormValidation.requiredIntegrations().includes("linkedin-pages")) {
      display = "block";
    }

    return (
      <div className="field" style={{ display: display }}>
        <label className="tw-label">LinkedIn Pages account {this.props.disconnectStore && "(Optional)"}</label>
        {this.props.disconnectStore && (
          <p className="text-sm italic mb-2">
            You can select the account in the "Integration" tab of the report to apply to all your widgets. If you need
            a different account for this widget, you can select it here.
          </p>
        )}
        <Select
          value={this.state.linkedInAccount}
          tabIndex={tabIndex}
          label="LinkedIn Pages account"
          options={store.getState().integrationsReducer.integrationsAccountLists["linkedin-pages"]}
          onChange={this.onChange}
        />
      </div>
    );
  },
});

export default LinkedInPagesAccountSelector;
