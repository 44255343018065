import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FilterValidation from "../../../../helpers/filter_validation";
import GenericListEditor from "../../../../widgets/data/list_editor";
import WidgetPreviewFetcher from "../../../../helpers/widget_preview/widget_preview_fetcher";
import AccountSelector from "../../../../legacy-components/account_selectors/mailchimp_account_selector";

const basicFilterOperators = [
  { value: "LIKE", label: "contains" },
  { value: "NOT LIKE", label: "does NOT contain" },
];

const filters = [
  { label: "List", value: "list" },
  { label: "Campaigns", value: "campaigns" },
  { label: "Email clients", value: "email_clients" },
  { label: "Country", value: "country" },
];

const dimensions = [
  { label: "List", value: "list" },
  { label: "Campaigns", value: "campaigns" },
  { label: "Email clients", value: "email_clients" },
  { label: "Country", value: "country" },
];

const noSecondaryMetric = ["email_clients", "country"];

let allMetrics = [];
class ListEditor extends React.Component {
  constructor(props) {
    super(props);
    allMetrics = store.getState().widgetReducer.mailchimpMetricsForCanvas;
  }

  saveAndClose() {
    let errors = [];

    if (!this.props.dimension) {
      errors.push("Select what you want to list");
    }

    if (!this.props.metric_name) {
      errors.push("Select the metric you want");
    }

    if (!FilterValidation.validate(this.props.filters)) {
      errors.push("At least one filter has an unspecified value");
    }

    if (errors.length > 0) {
      store.dispatch(setFlashMessage("We could not save the widget:", errors));
      return;
    }

    store.dispatch(setFlashMessage(undefined, []));
    this.props.save();

    this.close();
  }

  close() {
    new WidgetPreviewFetcher().fetch(this.props.widgetIndex);

    store.dispatch(setFlashMessage(undefined, [])); // reset the error, in case there was one set
    this.props.closeWidgetEditModal();
  }

  render() {
    let metricsList = allMetrics.filter((metric) => metric.valid_dimensions.includes(this.props.dimension));
    let filtersList = filters.filter((filter) => filter.value.includes(this.props.dimension));
    let offerPreviousPeriodComparison = false;
    if (this.props.dimension == "list") {
      offerPreviousPeriodComparison = true;
    }

    return (
      <GenericListEditor
        {...this.props}
        name="Mailchimp List"
        dimensions={dimensions}
        metricsList={metricsList}
        noSecondaryMetric={noSecondaryMetric}
        filterOperators={basicFilterOperators}
        filterDimensionList={filtersList}
        filterHasORenabled={true}
        saveAndClose={this.saveAndClose.bind(this)}
        closeWidgetEditModal={this.close.bind(this)}
        deleteWidget={this.props.deleteWidget.bind(this)}
        offerPreviousPeriodComparison={offerPreviousPeriodComparison}
        customAccountSelector={
          <AccountSelector
            disconnectStore
            onChange={this.props.updateCustomAccount}
            value={this.props.custom_account}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return state.widgetReducer.editorConfig;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateCustomAccount: (event) => {
      let value;
      if (event) {
        value = event.value;
      }
      dispatch(updateEditorWidgetConfig("custom_account", value, ownProps.widgetIndex));
    },
    updateLabel: (event) => {
      dispatch(updateEditorWidgetConfig("label", event.target.value, ownProps.widgetIndex));
    },
    updateMetricLabel: (event) => {
      dispatch(updateEditorWidgetConfig("metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      dispatch(updateEditorWidgetConfig("metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("metric_label", label, ownProps.widgetIndex));
    },
    updateDimension: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      let previousDimension = store.getState().widgetReducer.editorConfig.dimension;

      if (previousDimension != value) {
        dispatch(updateEditorWidgetConfig("metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("metric_label", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("secondary_metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("secondary_metric_label", "", ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("third_metric_name", undefined, ownProps.widgetIndex));
        dispatch(updateEditorWidgetConfig("third_metric_label", "", ownProps.widgetIndex));
      }

      dispatch(updateEditorWidgetConfig("dimension", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("dimension_label", label, ownProps.widgetIndex));
    },
    updateDimensionLabel: (event) => {
      dispatch(updateEditorWidgetConfig("dimension_label", event.target.value, ownProps.widgetIndex));
    },
    updateComparisonColumnName: (event) => {
      dispatch(
        updateEditorWidgetConfig(
          "secondary_column_is_previous_period_comparison",
          event.target.checked,
          ownProps.widgetIndex
        )
      );
    },
    updateComparisonColumnLabel: (event) => {
      dispatch(updateEditorWidgetConfig("comparison_column_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryLabel: (event) => {
      dispatch(updateEditorWidgetConfig("secondary_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateSecondaryMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label;
      }

      dispatch(updateEditorWidgetConfig("secondary_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("secondary_metric_label", label, ownProps.widgetIndex));
    },
    updateThirdLabel: (event) => {
      dispatch(updateEditorWidgetConfig("third_metric_label", event.target.value, ownProps.widgetIndex));
    },
    updateThirdMetric: (selected) => {
      let value, label;
      if (selected) {
        value = selected.value;
        label = selected.label.split(" -- ")[0];
      }

      dispatch(updateEditorWidgetConfig("third_metric_name", value, ownProps.widgetIndex));
      dispatch(updateEditorWidgetConfig("third_metric_label", label, ownProps.widgetIndex));
    },
    updateLimit: (event) => {
      dispatch(updateEditorWidgetConfig("limit", event.target.value, ownProps.widgetIndex));
    },
    updateDateFilter: (ranges, isEnabledDateFilter) => {
      if (isEnabledDateFilter) {
        const { startDate, endDate, key } = ranges;
        const dateFilter = { startDate, endDate, key };
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', dateFilter, ownProps.widgetIndex));
      } else {
        dispatch(updateEditorWidgetConfig('date_filter_by_widget', null, ownProps.widgetIndex));
      }

    },
    updateFiltersEnabled: (checked) => {
      dispatch(updateEditorWidgetConfig('is_enabled_date_filter', checked, ownProps.widgetIndex));
    },

    save: () => {
      dispatch(saveWidgetConfigFromEditor(ownProps.widgetIndex));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
